import style from './Label.module.css'

export const Label = ({ login, required, small, children }) => {
    const labelClasses = style.label
        + (login ? ' ' + style.label_login : '')
        + (small ? ' ' + style.label_small : '')
        + (required ? ' ' + style.label_required : '')

    return (
        <label className={labelClasses}>
            {children}
        </label>
    )
}