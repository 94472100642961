import style from './Input.module.css'
import { CloseIcon } from './CloseIcon'
import { SearchIcon } from './SearchIcon'
import { useRef } from 'react'
import { CopyBtn } from './CopyBtn'

export const Input = ({ login, formik, required, label, error, small, search, copy, ...props }) => {
    const ref = useRef()
    const inputClasses = style.input
        + (login ? ' ' + style.input_login : '')
        + (small ? ' ' + style.input_small : '')
        + (search ? ' ' + style.input_search : '')
        + (copy ? ' ' + style.input_copy : '')
        + (error ? ' ' + style.input_error : '')

    const searchLenth = props.value?.length
    const clearInput = () => props.onChange('')

    if (props.type === 'date') {
        return (
            <div className={inputClasses}>
                <input
                    {...props} 
                    onChange={e => props.onChange(formik ? e : e.target.value)}
                    ref={ref}
                    type={!props.value ? 'text' : props.type}
                    onFocus={() => ref.current.type = 'date'}
                    onBlur={() => {
                        if (!props.value) {
                            ref.current.type = 'text'
                        }
                    }}
                />
            </div>
        )
    }

    return (
        <div className={inputClasses}>
            { search && (!searchLenth ? <SearchIcon /> : <CloseIcon onClick={clearInput} />) }
            { copy && <CopyBtn onClick={copy} /> }
            <input
                {...props} 
                onChange={e => props.onChange(formik ? e : e.target.value)}
            />
        </div>
    )
}
