import { Container, Logo, Menu, ProfileData } from 'src/components'
import style from './Header.module.css'
import { useContext } from 'react'
import { AppContext } from 'src'
import { OBSERVER_MENU_ITEMS, S_ADMIN_MENU_ITEMS } from 'src/assets/data'
import { observer } from 'mobx-react-lite'
import { AppSettings } from 'src/modals'

export const Header = observer(() => {
    const { authStore, modalStore } = useContext(AppContext)

    const appSettingsModal = () => modalStore.setModal(
		<AppSettings onClose={() => { modalStore.setModal(null) } }/>)

    const handleLogout = () => {
        authStore.getLogout()
        // clear all data from stores
    }

    return (
        <div className={style.header}>
            <Container>
                <div className={style.header_container}>
                    <Logo/>

                    {authStore.isSuperAdmin &&
                        <Menu items={S_ADMIN_MENU_ITEMS} />}

                    {authStore.isObserver &&
                        <Menu items={OBSERVER_MENU_ITEMS} />}

                    <ProfileData
                        login={authStore.login}
                        handleSettings={appSettingsModal}
                        handleLogout={handleLogout} />
                </div>
            </Container>
        </div>
    )
})
